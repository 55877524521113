<template>
    <div @click="changeRoute" class="navigation-banner">
        <h2 class="title luxury">{{ headers?.title }}</h2>
        <div class="get-touch">
            <svg
                width="38"
                height="10"
                viewBox="0 0 38 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166
                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415
                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746
                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015
                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961
                              0.65 5.64961H35.0287L31.8652 8.75479Z"
                    fill="#182236"
                ></path>
            </svg>
            <span class="title fira uppercase">{{ headers.full_list_title }}</span>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {defineProps} from "vue";
import {useRouter} from "vue-router";

const props = defineProps({
    contentData: {
        type: Object,
    },
});
let router = useRouter()
let headers = computed(() => props.contentData?.conf.header || {})

const changeRoute = () => {
    if (headers.value.full_list_url?.includes('http')) {
        window.open(headers.value.full_list_url, '_blank');
        return
    }
    router.push({path: headers.value.full_list_url})
}
</script>

<style lang="scss" scoped>
.navigation-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 90px 0;
    color: $primaryNavy;
    @media only screen and (max-width: 1023px) {
        padding: 55px 0 25px;
    }

    .title {
        font-weight: 400;
        font-size: 55px;
        text-transform: uppercase;
        transition: all .45s ease-out;
        text-align: center;
        @media only screen and (max-width: 1023px) {
            font-size: 34px;
        }
    }

    .get-touch {
        display: flex;
        align-items: center;
        margin-top: 19px;
        cursor: pointer;

        &:hover {
            svg {
                animation: goBack .45s ease-out;
                opacity: .6;
            }

            .title {
                opacity: .6;
            }
        }

        .title {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            margin-left: 13px;
            transition: all .45s ease-out;
            @media only screen and (max-width: 1023px) {
                font-size: 13px;
            }
        }
    }

    svg {
        transition: all .45s ease-out;

        path {
            transition: all .45s ease-out;
        }
    }

    @keyframes goBack {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(5px);
        }
        100% {
            transform: translateX(0);
        }
    }

}
</style>

<style lang="scss">
body {
    &.is-dark {
        .navigation-banner {
            color: white;

            .get-touch {
                path {
                    fill: white !important;
                }
            }
        }
    }
}
</style>
